import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../components/Sidebar';
import AquiferHealthFiltersBar from '../components/AquiferHealthFiltersBar';
import PumpingVsDrawdownChart from '../components/PumpingVsDrawdownChart';
import { Typography } from '@material-ui/core';
import SchematicBottomNav from '../components/SchematicBottomNav';

// create page styles
const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: `flex`,
    },
    overflowX: `hidden`,
  },  
  content: {    
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
    minHeight: `100vh`,
    backgroundColor: `#f1f1f1`,
    padding: `15px 15px 65px 15px`,
  },
  dataViewerContent: {
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: 1100,
  },
  progressCircle: {
    marginTop: 20,
  },
  graphsSection: {
    marginTop: 30,
    backgroundColor: theme.palette.secondary.dark,
    marginLeft: -15,
    marginRight: -15,
    padding: `15px 15px 45px 15px`,
    color: `#ffffff`,
  },
  sectionTitle: {
    // marginTop: 15,
    marginBottom: 5,
  },
  sectionText: {
    marginBottom: 10,
  },
  screenshot: {
    maxWidth: `100%`,
  },
  smallItalic: {
    fontStyle: 'italic',
    fontSize: 'small', // You can also use a specific size like '12px'
    marginBottom: 10,
  },
});

const AquiferHealth = (props) => {
  const { classes, auth, history } = props; 

  // Create request headers with token authorization
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}` };
  const [refreshData, setRefreshData] = useState(false);

  const handleRefreshData = (state) => {
    setRefreshData(state);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar
        auth={auth}
        login={auth.login}
        logout={auth.logout} 
        loggedIn={auth.isAuthenticated()} 
        history={history} />
      <div className={classes.content}>
        <div className={classes.dataViewerContent}>
          <AquiferHealthFiltersBar headers={headers} auth={auth} handleRefreshData={handleRefreshData} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PumpingVsDrawdownChart headers={headers} refreshData={refreshData} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>Long Term Trends for Aquifer Level Recovery</Typography>
              <Typography variant="body1" className={classes.sectionText}><strong>Static Water Level </strong> is the level of the groundwater without any impacts of pumping.  To determine static water levels within this Wellfield, the highest monthly level measured for each well after a period of at least 8 days of non-pumping was determined and then the highest of these across all wells was chosen to represent the wellfield for that month.  During periods of heavy pumping, these calculated static water levels may be lower than the actual static water levels.</Typography>
              <Typography className={classes.smallItalic}>Note that a trends with an R-squared value of less than 0.4 is not significant, while an R-squared value over 0.7 indicates a strong trend.</Typography>
              {/*<img src={AqHealthScreenshot} className={classes.screenshot} alt="Aquifer Health Graphs" />*/}
              <div style={{width: "100%", height: "1000px", position: "relative"}}>
                <embed
                    src={"https://docs.google.com/spreadsheets/d/e/2PACX-1vQnjZzdhGzZkdlszRmIuPkC6f88--LgBAobZvoThKFSM0HeWeFt3T-gqfKYdRRhHYB1qLQIptM32YGg/pubhtml?gid=1357936055&amp;single=true&amp;widget=true&amp;headers=false"}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    title="Aquifer Health"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Hidden mdUp>
        <SchematicBottomNav history={history} />
      </Hidden>
    </div>
  )
}

AquiferHealth.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AquiferHealth);
