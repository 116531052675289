import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import SystemsIcon from '@material-ui/icons/Dashboard';
import AqHealthIcon from '@material-ui/icons/Opacity';
import {LocalDrink} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    position: `fixed`,
    bottom: 0,
    zIndex: 1000,
    borderTop: `2px solid ${theme.palette.secondary.main}`,
  },
  selected: {
    color: theme.palette.secondary.main,
  }
}));

export default function SchematicBottomNav({history, theme}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // function for naviating to a specific page in the app
  const goTo = (route) => {
    history.push(`${route}`);
    localStorage.setItem('last_url', history.location.pathname);
  }

  const handleChange = (val) => {
    setValue(val);
    goTo(val)
  }

  useEffect(() => {
    setValue(history.location.pathname);
  }, []);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction 
        label="Systems" 
        value="/" 
        classes={{ selected: classes.selected}} 
        icon={
          <HomeIcon style={{ color: value === '/' ? `#4074DC` : `#333333`}} />
        } />
      <BottomNavigationAction 
        label="Data Viewer" 
        value="/data-viewer"
        classes={{ selected: classes.selected}}  
        icon={
          <SystemsIcon style={{ color: value === '/data-viewer' ? `#4074DC` : `#333333`}} />
        } />
      <BottomNavigationAction 
        label="Aquifer Health" 
        value="/aquifer-health"
        classes={{ selected: classes.selected}}
        icon={
          <AqHealthIcon style={{ color: value === '/aquifer-health' ? `#4074DC` : `#333333`}} />
        } />
      <BottomNavigationAction
          label="Well Health"
          value="/well-health"
          classes={{ selected: classes.selected}}
          icon={
            <LocalDrink style={{ color: value === '/well-health' ? `#4074DC` : `#333333`}} />
          } />
    </BottomNavigation>
  );
}