import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import useFetchData from '../hooks/useFetchData';

// create page styles
const styles = theme => ({
  root: {
    padding: 15,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: `block`,
      marginTop: 20,
      marginBottom: 20,
    },
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  btn: {
    marginRight: 15,
    marginTop: 8,
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SystemFiltersBar = ({ classes, headers, auth, handleRefreshData }) => {
  const { userProfile, getProfile } = auth;  
  const [user, setUser] = useState(null);
  const [LocationsData, isLoaded] = useFetchData('locations/system', headers, []);
  const [pastFilterSelections, filtersLoaded] = useFetchData('system/filters', headers, []);
  const [values, setValues] = useState({
    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    locations: [1,2,3,4,5,6],
    user_id: user,
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value, 
    }))
  }

  const handleSubmit = async (event) => {
    handleRefreshData(false);
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/system/filters`, values, { headers });
      handleRefreshData(true);
      // setWaitingState('complete', 'no error');
    } catch (err) {
      console.error(err);
      handleRefreshData(true);
      // setWaitingState('complete', 'error');
    }    
  }

  // retrieve the user's profile info on page load
  // passing an empty array as the last argument ensures that
  // this code only runs once
  useEffect(() => {
    if (!userProfile) {
      getProfile((err, profile) => {
        setUser(profile.sub);
        setValues({ ...values, user_id: profile.sub });
      });
    } else {      
      setUser(userProfile.sub);
      setValues({ ...values, user_id: userProfile.sub });
    }
  }, []);

  useEffect(() => {
    if (pastFilterSelections.length > 0) {
      setValues(prevState => ({
        ...prevState,
        start_date: pastFilterSelections[0].start_date,
        end_date: pastFilterSelections[0].end_date,
        locations: pastFilterSelections[0].locations,
      }));
    } else {
      setValues(prevState => ({
        ...prevState,
        start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        locations: [1,2,3,4,5,6],
      }));
    }
  }, [pastFilterSelections]);

  return (    
    <Paper className={classes.root}>
      <form method="post" className={classes.filters} onSubmit={handleSubmit}>
        <Button type="submit" variant="contained" color="secondary" className={classes.btn}>Apply</Button>
        <TextField
          id="start-date"
          label="Start Date"
          type="date"
          name="start_date"
          onChange={handleChange}
          value={values.start_date}
          className={classes.textField}
        />
        <TextField
          id="end_date"
          label="End Date"
          type="date"
          name="end_date"
          onChange={handleChange}
          value={values.end_date}
          className={classes.textField}
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox">Location(s)</InputLabel>
          <Select
            multiple
            value={values.locations}
            onChange={handleChange}
            name="locations"
            input={<Input id="select-multiple-checkbox" />}
            renderValue={selected => LocationsData.filter(d => selected.includes(d.location_ndx)).map(d => d.location_desc).join(', ')}
            MenuProps={MenuProps}
          >
            {LocationsData.map(loc => (
              <MenuItem key={loc.location_ndx} value={loc.location_ndx}>
                <Checkbox checked={values.locations.indexOf(loc.location_ndx) > -1} />
                <ListItemText primary={loc.location_desc} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    </Paper>
  )
}

SystemFiltersBar.propTypes = {
  handleRefreshData: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(SystemFiltersBar);
