import React from 'react';
import { Route, Router, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Callback from './components/Callback';
import Auth from './components/Auth';
import history from './components/History';
import ErrorBoundary from './components/ErrorBoundary';
import Login from './pages/Login';
// import Home from './pages/Home';

import System from './pages/System';
import DataViewer from './pages/DataViewer';
import AquiferHealth from './pages/AquiferHealth';
import WellHealth from "./pages/WellHealth";

const auth = new Auth();

const handleAuthentication = ({location}) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

// This allows us to wrap the entire application in our custom theme
const theme = createMuiTheme({
  palette: {
    background: {
      default: `#f1f1f1`
    },
    primary: { 
      light: '#4A6572',
      main: '#344955',
      dark: '#232F34', 
    },
    secondary: { 
      light: `#bbd2ff`,
      main: '#4074DC',
      dark: '#293e68',
    },
    accent: {
      main: '#FF6868',
    },
    overrides: {
      body: {
        backgroundColor: `#f1f1f1`
      }
    }
  },
  typography: {
    useNextVariants: true,
  }
});

export const makeMainRoutes = () => {
  localStorage.setItem('last_url', history.location.pathname);

  return (
      <ErrorBoundary>
        <Router history={history}>
          <MuiThemeProvider theme={theme}>
            {/* <Route path="/" exact render={props => <SchematicHome auth={auth} {...props} />} /> */}
            <Route path="/login" exact render={props => <Login auth={auth} {...props} />} />
            <Route 
              path="/" 
              exact
              render={props =>
                !auth.isAuthenticated()
                  ? <Redirect to="/login" />
                  : <System auth={auth} {...props} />}
            />  
            <Route 
              path="/data-viewer" 
              exact
              render={props =>
                !auth.isAuthenticated()
                  ? <Redirect to="/login" />
                  : <DataViewer auth={auth} {...props} />}
            />  
            <Route 
              path="/aquifer-health" 
              exact
              render={props =>
                !auth.isAuthenticated()
                  ? <Redirect to="/login" />
                  : <AquiferHealth auth={auth} {...props} />}
            />
            <Route
                path="/well-health"
                exact
                render={props =>
                    !auth.isAuthenticated()
                        ? <Redirect to="/login" />
                        : <WellHealth auth={auth} {...props} />}
            />
            <Route
              path="/callback"
              render={props => {
                handleAuthentication(props);
                return <Callback {...props} />;
              }}
            />
          </MuiThemeProvider>
        </Router>
      </ErrorBoundary>
  );
}
