import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import useFetchData from '../hooks/useFetchData';
import ProductionOverviewStyles from '../styles/ProductionOverviewChart.css';

const styles = theme => ({
  sectionTitle: {
    // marginTop: 15,
    marginBottom: 5,
  },
  cardTitle: {
    fontSize: 18,
    textTransform: `capitalize`,
  },
  metricTitle: {
    fontSize: 12,
  },
  paper: {
    padding: 10,
    cursor: `pointer`,
    '&:hover': {
      cursor: `pointer!important`
    }
  },
  titleRow: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    marginBottom: 15,
  },
  row: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    marginBottom: 5,
  },
  grid: {
    marginBottom: 10,
  },
  progress: {
    margin: 15,
  },
  statusChip: {
    backgroundColor: theme.palette.secondary.main,
    color: `#ffffff`,
    padding: `3px 8px`,
    borderRadius: 16,
    fontSize: 12,
  },
  timestamp: {
    fontSize: 11,
    marginTop: 10,
    color: `#888888`,
    textAlign: `right`,
  },
  legend: {
    display: `flex`,
    justifyContent: `space-around`,
    alignItems: `flex-end`
  },
  legendItem: {
    textAlign: `center`
  },
  legendBox: {
    width: 35,
    height: 15,
    display: `inline-block`,
    verticalAlign: `middle`,
    marginRight: 5,
  },
  totalRow: {
    fontWeight: `bold`,
    backgroundColor: theme.palette.primary.main,
    color: `#ffffff`,
  }
});


const ProductionTotalsChart = ({ classes, theme, headers, history, refreshData }) => {
  const [loaded, setLoaded] = useState(false);
  const [legendData, setLegendData] = useState([]);
  const [GraphData, isLoaded] = useFetchData(`system/production-totals`, headers, [refreshData]);
  const [totalBarrels, setTotalBarrels] = useState(0);

  const calcPct = (bbl) => {
    const calc = ((bbl/totalBarrels)*100).toFixed(1);
    return isNaN(calc) ? 0 : calc;
  }

  /**
  * This method is used to set the chart dimensions
  * @param {string} selector  [CSS selector for chart]
  * @param {object} [margin] [margins to apply]
  * @return {object} [chart dimensions]
  */
  const setChartDimensions = (selector, margin = { top: 15, right: 15, bottom: 25, left: 50 }) => {
    let width = d3.select(selector).style('width');
    width = parseInt(width.substring(0, width.length -2));

    let height = width * .55;

    // Subtract margins from width and height
    width = width - margin.left - margin.right;
    height = height - margin.top - margin.bottom;

    const chartDimensions = {
      margin: margin,
      width: width,
      height: height
    }
    return chartDimensions;
  };

  const setTableColor = (val) => {
    const color = d3.scaleOrdinal()
      .domain(GraphData.map(d => d.location_desc))
      .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), GraphData.length));
    
    if (color.domain().length === 1) {
      return `rgb(66, 136, 181)`
    }
    return color(val)
  }

  const createChart = () => {
    const chartDimensions = setChartDimensions('.production-totals-graph', { top: 15, right: 32, bottom: 15, left: 32});
    const { width, height, margin } = chartDimensions;

    const color = d3.scaleOrdinal()
      .domain(GraphData.map(d => d.location_desc))
      .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), GraphData.length));

    setLegendData(color.domain().map((d,i) => ({
      location_desc: d,
      color: (() => {
        if (color.domain().length === 1) {
          return `rgb(66, 136, 181)`
        }
        return color(d)
      })()
    })));

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(width, height) / 2;
    
    // Compute the position of each group on the pie:
    const pie = d3.pie()
      .value((d) => {
        return d.total_bbl;
      })
    // const data_ready = pie(d3.entries(GraphData));

    const arc = d3.arc()
        .innerRadius(0)
        .outerRadius(radius);  

    if (document.querySelector('.production-totals-graph-svg') !== null) {
      document.querySelector('.production-totals-graph-svg').remove();
    };

    // Create the SVG
    const svg = d3.select('.production-totals-graph')
      .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('class', 'production-totals-graph-svg')
      .append('g')
        .attr('transform', `translate(${width/2+margin.left}, ${height/2+margin.top})`);
    
    // Join new data
    const arcs = svg.selectAll("path")
      .data(pie(GraphData));
    
    // Enter new arcs
    arcs.enter().append("path")
      .attr("fill", (d) => {
        if (color(d.data.location_desc) === `rgb(0, 0, 0)`) {
          return `rgb(66, 136, 181)`;
        }
        return color(d.data.location_desc);
      })
      .attr("d", arc)
      // .attr("stroke", "white")
      // .attr("stroke-width", "1px")
      .each(function(d) { this._current = d; });
  }

  /**
   * reset the loaded boolean whenever the locGroup changes
   */
  useEffect(() => {    
    setLoaded(false);
  }, [refreshData]);

  useEffect(() => {
    if (!loaded && GraphData.length > 0) {
      setLoaded(true);
      createChart();
    }
  }, [GraphData]);
  
  useEffect(() => {
    setTotalBarrels(d3.sum(GraphData, d => d.total_bbl));
  }, [GraphData]);

  return (    
    <Fade in={true} style={{ transitionDelay: '500ms'}}>
      <React.Fragment>
        <Typography variant="h6" className={classes.sectionTitle}>Production Totals</Typography>
        {loaded && GraphData.length > 0 ? (
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Location Name</TableCell>
                  <TableCell>Barrels</TableCell>
                  <TableCell>% Contribution</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.totalRow}>Total</TableCell>
                  <TableCell className={classes.totalRow}>{totalBarrels.toLocaleString()}</TableCell>
                  <TableCell className={classes.totalRow}>100%</TableCell>
                </TableRow>
                {GraphData.map(d => (
                  <TableRow key={d.location_ndx}>
                    <TableCell component="th" scope="row">
                      <span className={classes.legendBox} style={{backgroundColor: setTableColor(d.location_desc)}}></span>
                      {d.location_desc}
                    </TableCell>
                    <TableCell>{d.total_bbl.toLocaleString()}</TableCell>
                    <TableCell>{calcPct(d.total_bbl)}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="production-totals-graph"></div>
            <div className={classes.legend}>
              {legendData.map((d) => (
                <div key={d.location_desc} className={classes.legendItem}>
                  <div 
                    className={classes.legendBox} 
                    style={{
                      backgroundColor: d.color,
                      border: `1.5px solid #858585`
                    }}></div>
                  <div className={classes.legendText}>{d.location_desc}</div>
                </div>
              ))}
            </div>
          </Paper>
        ) : (            
          <Paper className={classes.paper}>
            <table></table>
            <div className="production-totals-graph" style={{visibility: `hidden`, height: 0}}></div>  
            <CircularProgress className={classes.progress} color="secondary" />
          </Paper>
        )}
      </React.Fragment>
    </Fade>
  );
}

ProductionTotalsChart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  refreshData: PropTypes.bool,
  // locGroup: PropTypes.number.isRequired,
  // timePeriod: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProductionTotalsChart);