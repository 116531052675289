import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../components/Sidebar';
import { Typography } from '@material-ui/core';
import SchematicBottomNav from '../components/SchematicBottomNav';

// create page styles
const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: `flex`,
    },
    overflowX: `hidden`,
  },  
  content: {    
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
    minHeight: `100vh`,
    backgroundColor: `#f1f1f1`,
    padding: `15px 15px 65px 15px`,
  },
  dataViewerContent: {
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: 1100,
  },
  progressCircle: {
    marginTop: 20,
  },
  graphsSection: {
    marginTop: 30,
    backgroundColor: theme.palette.secondary.dark,
    marginLeft: -15,
    marginRight: -15,
    padding: `15px 15px 45px 15px`,
    color: `#ffffff`,
  },
  sectionTitle: {
    // marginTop: 15,
    marginBottom: 5,
  },
  sectionText: {
    marginBottom: 10,
  },
  screenshot: {
    maxWidth: `100%`,
  },
  smallItalic: {
    fontStyle: 'italic',
    fontSize: 'small', // You can also use a specific size like '12px'
    marginBottom: 10,
  },
});

const WellHealth = (props) => {
  const { classes, auth, history } = props; 

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar
        auth={auth}
        login={auth.login}
        logout={auth.logout} 
        loggedIn={auth.isAuthenticated()} 
        history={history} />
      <div className={classes.content}>
        <div className={classes.dataViewerContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>Well Health Overview - 7-year Specific Capacity Trends</Typography>
              <Typography variant="body1" className={classes.sectionText}><strong>Specific Capacity </strong> generally reflects well, pump, and sometimes aquifer performance.  It is calculated by dividing a well’s pumping rate by the aquifer drawdown while it's pumping and expressed in GPM/Foot.  Monitoring specific capacity through time helps to identify declines in well performance and develop well maintenance schedules. </Typography>
              <Typography className={classes.smallItalic}>Note that trends on the graphs below with an R-squared value of less than 0.4 are not significant, while R-squared values over 0.7 indicate a strong trend.  A well with a statistically significant decrease in Specific Capacity over time, despite well servicing, may have issues related to scaling or biofouling, and should be investigated further.</Typography>
              <div style={{width: "100%", height: "2175px", position: "relative"}}>
                <embed
                    src={"https://docs.google.com/spreadsheets/d/e/2PACX-1vTNc7BtFrXgsbvKMn3LPNsKho_Uca0PRe7zj1dOTB4YzgSgy1ypTO67_u0a-KyJ2Uo2JCcOK98taBW8/pubhtml?gid=237077729&amp;single=true&amp;widget=true&amp;headers=false"}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    title="Well Health"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Hidden mdUp>
        <SchematicBottomNav history={history} />
      </Hidden>
    </div>
  )
}

WellHealth.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WellHealth);
