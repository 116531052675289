import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../components/Sidebar';
import DataViewerFiltersBar from '../components/DataViewerFiltersBar';
import PumpingRatesChart from '../components/PumpingRatesChart';
import DepthToWaterChart from '../components/DepthToWaterChart';
import PressuresChart from '../components/PressuresChart';
import PressuresMaxChart from '../components/PressuresMaxChart';
import SchematicBottomNav from '../components/SchematicBottomNav';

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

// create page styles
const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: `flex`,
    },
    overflowX: `hidden`,
  },  
  content: {    
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
    minHeight: `100vh`,
    backgroundColor: `#f1f1f1`,
    padding: `15px 15px 65px 15px`,
  },
  dataViewerContent: {
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: 1100,
  },
  progressCircle: {
    marginTop: 20,
  },
  graphsSection: {
    marginTop: 30,
    backgroundColor: theme.palette.secondary.dark,
    marginLeft: -15,
    marginRight: -15,
    padding: `15px 15px 45px 15px`,
    color: `#ffffff`,
  }
});

const DataViewer = (props) => {
  const { classes, auth, history } = props; 

  // Create request headers with token authorization
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}` };
  const [refreshData, setRefreshData] = useState(false);

  const handleRefreshData = (state) => {
    setRefreshData(state);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar
        auth={auth}
        login={auth.login}
        logout={auth.logout} 
        loggedIn={auth.isAuthenticated()} 
        history={history} />
      <div className={classes.content}>
        <div className={classes.dataViewerContent}>
          <DataViewerFiltersBar headers={headers} auth={auth} handleRefreshData={handleRefreshData} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PumpingRatesChart headers={headers} refreshData={refreshData} />
            </Grid>
            <Grid item xs={12}>
              <DepthToWaterChart headers={headers} refreshData={refreshData} />
            </Grid>
            <Grid item xs={12}>
              <PressuresChart headers={headers} refreshData={refreshData} />
            </Grid>
            <Grid item xs={12}>
              <PressuresMaxChart headers={headers} refreshData={refreshData} />
            </Grid>
          </Grid>
        </div>
      </div>
      <Hidden mdUp>
        <SchematicBottomNav history={history} />
      </Hidden>
    </div>
  )
}

DataViewer.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DataViewer);
