import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { makeMainRoutes } from './Routes';

// Configure error tracking with Sentry
Sentry.init({ 
  dsn: 'https://3b6450eedd4a4b2a91287f66046322d9@sentry.io/1525584',
  environment: process.env.REACT_APP_ENVIRONMENT,  
});

// initialize routes
const routes = makeMainRoutes();

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
