import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

mapboxgl.accessToken =  'pk.eyJ1IjoibHJld2F0ZXIiLCJhIjoiY2p4ZGc4MDBvMGR1djN6czBzdHBoeTJiNSJ9.RchErR0J9G5JEoHi6ZnXyg';

// create page styles
const styles = theme => ({
  map: {
    width: '100%',
    height: 550,
    borderRadius: 4,
  },
});

const Map = (props) => {
  const { classes, items, activePage } = props;

  const mapContainer = useRef(null); // create a reference to the map container
  const [map, setMap] = useState(null);

  useEffect(() => {
    // Declare mapbox map
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/lrewater/cjyesoz3b003m1coa6xcfc87l',
      center: [-103.186937, 31.85],
      zoom: 12,
    });
  
    // Add a navigation control
    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'top-left');
  
    map.on('click', (e) => {
      // const point = e;
      // const { lng, lat } = point.lngLat;
  
      // setMarker((marker) => {
      //   if (marker !== null) {
      //     marker.remove();
      //   }
      //   const newMarker = new mapboxgl.Marker()
      //     .setLngLat(point.lngLat)
      //     .addTo(map);
      //   return newMarker;
      // });
      
      // setValues((values) => {
      //   return {...values, centroid_lat_dd: lat, centroid_lon_dd: lng };
      // })
    });
    setMap(map);
  }, [])

  return (    
    <Paper className={classes.root}>
      <div className={classes.map} ref={mapContainer}></div>
    </Paper>
  )
}

Map.propTypes = {
  
};

export default withStyles(styles, { withTheme: true })(Map);
