import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// import useHttp from '../hooks/useHttp';

// create page styles
const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: `center`,
  },
  loginBlock: {
    width: `90%`,
    [theme.breakpoints.up('md')]: {
      width: `25%`,
    },
    [theme.breakpoints.down('md')]: {
      width: `45%`,
    },
    [theme.breakpoints.down('xs')]: {
      width: `90%`,
    },
    marginTop: 60,
    padding: 20,
    textAlign: `center`,
  },
  description: {
    marginTop: 20,
    marginBottom: 20,
  }
});

const Login = (props) => {
  const { classes, auth, history } = props;

  const { renewSession } = auth;

  const [loggedIn, setLoggedIn] = useState(false);

  const login = () => {
    auth.login();
    setLoggedIn(true);
  }

  const logout = () => {
    auth.logout();
    setLoggedIn(false);
  }

  const renewToken = () => {
    renewSession();
  }

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }, [loggedIn])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.loginBlock}>
        <Typography variant="h4" color="primary" className={classes.title}>Winkler Dashboard</Typography>
        <Typography variant="body1" className={classes.description}>Welcome to the Winkler Dashboard. To continue, please login. For questions or support please contact Leonard Rice Engineers.</Typography>
        <Button variant="contained" color="secondary" onClick={login}>Login</Button>
      </Paper>
    </div>
  )
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);
