import { useState, useEffect } from 'react'
import axios from 'axios';

const useFetchData = (endpoint, headers, dependencies = []) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setIsLoading(true);
    async function getData() {
      try {
        const fetchedData = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`, { headers }); 
        setIsLoading(false);    
        setData(fetchedData.data);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    }
    getData();
  }, dependencies);
  
  return [data, isLoading];
}

export default useFetchData;