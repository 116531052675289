import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import useFetchData from '../hooks/useFetchData';
import Sidebar from '../components/Sidebar';
import Map from '../components/Map';
import WellsOverview from '../components/WellsOverview';
import SystemFiltersBar from '../components/SystemFiltersBar';
import ProductionOverviewChart from '../components/ProductionOverviewChart';
import ProductionTotalsChart from '../components/ProductionTotalsChart';
import SchematicBottomNav from '../components/SchematicBottomNav';

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

// create page styles
const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: `flex`,
    },
    overflowX: `hidden`,
  },  
  content: {    
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
    minHeight: `100vh`,
    backgroundColor: `#f1f1f1`,
    padding: `15px 15px 65px 15px`
  },
  schematicContent: {
    padding:  `15px 15px 0 15px`,
    // marginTop: -50,
    marginLeft: `auto`,
    marginRight: `auto`,
    position: `relative`,
    zIndex: 1000,
    maxWidth: 700,
    [theme.breakpoints.up('md')]: {
      // marginTop: -100,
    },
  },
  progressCircle: {
    marginTop: 20,
  },
  graphsSection: {
    marginTop: 30,
    backgroundColor: theme.palette.secondary.dark,
    marginLeft: -15,
    marginRight: -15,
    padding: `15px 15px 45px 15px`,
    color: `#ffffff`,
  }
});

const System = (props) => {
  const { classes, auth, history } = props; 

  // Create request headers with token authorization
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}` };
  const [refreshData, setRefreshData] = useState(false);

  const handleRefreshData = (state) => {
    setRefreshData(state);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar
        auth={auth}
        login={auth.login}
        logout={auth.logout} 
        loggedIn={auth.isAuthenticated()} 
        history={history} />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Map />
          </Grid>
          <Grid item xs={12} sm={7}>
            <WellsOverview headers={headers} />
          </Grid>
        </Grid>
        <SystemFiltersBar headers={headers} auth={auth} handleRefreshData={handleRefreshData} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <ProductionTotalsChart headers={headers} refreshData={refreshData} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <ProductionOverviewChart headers={headers} refreshData={refreshData} />
          </Grid>
        </Grid>
      </div>
      <Hidden mdUp>
        <SchematicBottomNav history={history} />
      </Hidden>
    </div>
  )
}

System.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(System);
