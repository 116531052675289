import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import OpacityIcon from '@material-ui/icons/Opacity';
import AccountIcon from '@material-ui/icons/AccountCircle';
import GearIcon from '@material-ui/icons/Settings';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import logo from '../images/logo.png';

import useFetchData from '../hooks/useFetchData';
import {LocalDrink} from "@material-ui/icons";

const drawerWidth = 300;
const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    color: `#ffffff`,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    // backgroundColor: theme.palette.primary.main,
    padding: `10px 10px`,
    textAlign: `center`,
    ...theme.mixins.toolbar,
  },
  toolbarLogo: {
    maxWidth: 50,
    marginRight: 25
  },
  mobileToolbar: {
    backgroundColor: theme.palette.primary.main,
    color: `#ffffff`,
    justifyContent: `space-between`
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: `auto!important`,
    background: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    maxHeight: 80,
  },
  nav: {
    color: `#ffffff`,
  },
  navIcon: {
    color: `#ffffff`,
  },
  navText: {
    '& span' : {
      fontSize: `18px!important`
    }
  }
});


const Sidebar = ({ login, logout, loggedIn, auth, history, classes, theme, container }) => {
  // Create request headers with token authorization
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}` };

  const [mobileOpen, setMobileOpen] = useState(false);
  const [maintenanceData, isLoaded] = useFetchData(`maintenance/auth`, headers, []);
  const [maintenanceLink, setMaintenanceLink] = useState(null);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // function for naviating to a specific page in the app
  const goTo = (route) => {
    history.push(`/${route}`);
    localStorage.setItem('last_url', history.location.pathname);
  }

  const setActive = (route) => {
    if (history.location.pathname.includes(route) && route !== '/') {
      return true;
    } else if (route === '/' && history.location.pathname === '/') {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (typeof maintenanceData.role_access_string !== 'undefined') {
      setMaintenanceLink(maintenanceData.role_access_string);
    }
  }, [maintenanceData])

  const drawer = (
    <div id="sidebar">
      <div className={classes.toolbar}>
        <img src={logo} className={classes.logo} alt="Logo" />
      </div>
      <List className={classes.nav}>
        { loggedIn ? (
          <React.Fragment>
            <ListItem button onClick={() => goTo('')} selected={setActive('/')}>
              <ListItemIcon className={classes.navIcon}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText className={classes.navText} primary="System Overview" />
            </ListItem>
            <ListItem button onClick={() => goTo('data-viewer')} selected={setActive('/data-viewer')}>
              <ListItemIcon className={classes.navIcon}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText className={classes.navText} primary="Data Viewer" />
            </ListItem>
            <ListItem button onClick={() => goTo('aquifer-health')} selected={setActive('/aquifer-health')}>
              <ListItemIcon className={classes.navIcon}>
                <OpacityIcon />
              </ListItemIcon>
              <ListItemText className={classes.navText} primary="Aquifer Health" />
            </ListItem>
            <ListItem button onClick={() => goTo('well-health')} selected={setActive('/well-health')}>
              <ListItemIcon className={classes.navIcon}>
                <LocalDrink />
              </ListItemIcon>
              <ListItemText className={classes.navText} primary="Well Health" />
            </ListItem>
            { maintenanceLink !== null &&
              <ListItem button onClick={() => window.location.href = maintenanceLink}>
              <ListItemIcon className={classes.navIcon}>
                <GearIcon />
              </ListItemIcon>
              <ListItemText className={classes.navText} primary="Maintenance" />
            </ListItem>
            }
            <ListItem button>
              <ListItemIcon className={classes.navIcon}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText className={classes.navText} primary="Logout" onClick={logout} />
            </ListItem>
          </React.Fragment>
        ) : (
          <ListItem button>
            <ListItemIcon className={classes.navIcon}>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText className={classes.navText} primary="Login" onClick={login} />
          </ListItem>
        )
        }
      </List>
    </div>
  );

  return (
    <div>
      <Toolbar className={classes.mobileToolbar}>
        <IconButton
          color="default"
          aria-label="Open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" noWrap>
          Dashboard
        </Typography>
        <img src={logo} className={classes.toolbarLogo} alt="B2O Logo" />  
      </Toolbar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Sidebar);