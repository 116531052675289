import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

import useFetchData from '../hooks/useFetchData';
import PumpingVsDrawdownStyles from '../styles/PumpingVsDrawdownChart.css';

const styles = theme => ({
  sectionTitle: {
    // marginTop: 15,
    marginBottom: 5,
  },
  cardTitle: {
    fontSize: 18,
    textTransform: `capitalize`,
  },
  metricTitle: {
    fontSize: 12,
  },
  paper: {
    padding: 10,
    cursor: `pointer`,
    '&:hover': {
      cursor: `pointer!important`
    }
  },
  titleRow: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    marginBottom: 15,
  },
  row: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    marginBottom: 5,
  },
  grid: {
    marginBottom: 10,
  },
  progress: {
    margin: 15,
  },
  statusChip: {
    backgroundColor: theme.palette.secondary.main,
    color: `#ffffff`,
    padding: `3px 8px`,
    borderRadius: 16,
    fontSize: 12,
  },
  timestamp: {
    fontSize: 11,
    marginTop: 10,
    color: `#888888`,
    textAlign: `right`,
  },
  legend: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `flex-end`
  },
  legendItem: {
    textAlign: `center`,
    marginRight: 25,
  },
  legendBox: {
    width: 35,
    height: 15,
    display: `inline-block`,
    backgroundColor: `rgb(66, 136, 181, 0.15)`,
    border: `1.5px solid rgb(66, 136, 181, 0.3)`
  },
  legendLine: {
    width: 35,
    height: 3,
    display: `inline-block`,
    backgroundColor: `rgb(230, 85, 13)`,
  }
});


const PumpingVsDrawdownChart = ({ classes, theme, headers, history, refreshData }) => {
  const [loaded, setLoaded] = useState(false);
  const [legendData, setLegendData] = useState([]);
  const [GraphData, isLoaded] = useFetchData(`aquifer-health/pumping-vs-drawdown`, headers, [refreshData]);

  /**
  * This method is used to set the chart dimensions
  * @param {string} selector  [CSS selector for chart]
  * @param {object} [margin] [margins to apply]
  * @return {object} [chart dimensions]
  */
  const setChartDimensions = (selector, margin = { top: 15, right: 15, bottom: 25, left: 50 }) => {
    let width = d3.select(selector).style('width');
    width = parseInt(width.substring(0, width.length -2));

    let height = width * .75;
    height = height > 400 ? 400 : height;

    // Subtract margins from width and height
    width = width - margin.left - margin.right;
    height = height - margin.top - margin.bottom;

    const chartDimensions = {
      margin: margin,
      width: width,
      height: height
    }
    return chartDimensions;
  };

  const createTooltipLine = (mx, height, tooltipBar, tooltipLine) => {
    tooltipBar
      .attr('x1', mx)
      .attr('x2', mx)
      .attr('y1', 0)
      .attr('y2', height)
      .style('stroke', '#5d86ff')
      .style('stroke-width', '36px')
      .style('opacity', 0.1);
          
    tooltipLine
      .attr('x1', mx)
      .attr('x2', mx)
      .attr('y1', 0)
      .attr('y2', height)
      .style('stroke', '#5d86ff')
      .style('stroke-width', '1px')
      .style('opacity', 0.8);
  }

  const createTooltip = (tooltip, event, color, activeData, date) => {
    const html = activeData.map((d) => {
      return `
        <div class="tooltip-item">
          <span>Pumping: ${d.pumping_gpm} gpm</span><br/>
          <span>Level: ${d.depth_above_pump_ft} feet</span>
        </div>
      `;
    });

    html.unshift(`<div>${date}`);

    tooltip
      .html(html.join(''))
      .style('opacity', 1)		
      .style('left', `${event.pageX +15}px`)		
      .style('top', `${event.pageY-15}px`);
  }

  const createChart = () => {
    const chartDimensions = setChartDimensions('.pumping-vs-drawdown-graph', { top: 15, right: 65, bottom: 60, left: 65});
    const { width, height, margin } = chartDimensions;

    const y1Data = {
      data: GraphData,
      series: [...new Set(GraphData.map(d => d.location_ndx))],
      seriesNames: [...new Set(GraphData.map(d => d.location_desc))],
      max: d3.max(GraphData, d => d.pumping_gpm) * 1.1,
      min: d3.min(GraphData, d => d.pumping_gpm) < 0 ? d3.min(GraphData, d => d.pumping_gpm) : 0,
    };

    // Define the div for the tooltip
    if (document.querySelector('.tooltip-pumping-vs-drawdown') !== null) {
      document.querySelector('.tooltip-pumping-vs-drawdown').remove();
    };

    const tooltip = d3.select("body").append("div")	
      .attr("class", "tooltip-pumping-vs-drawdown")				
      .style("opacity", 0);

    // Create the x graph scale and set the domain/range for the graph
    const x = d3.scaleTime()
      .domain(d3.extent(GraphData, d => new Date(`${d.date_time}`)))
      .range([0, width]); 
    
    // Create the left y axis scale and set the domain/range for the graph
    const y = d3.scaleLinear()
      .domain([y1Data.min, y1Data.max+10])
      .range([height, 0]);
    
    // Create the right y axis scale and set the domain/range for the graph
    const y2 = d3.scaleLinear()
      .domain([0, 400])
      .range([height, 0]);

    const startDate = moment(x.domain()[0]);
    const endDate = moment(x.domain()[1]);
    const dateRange = endDate.diff(startDate, 'days');

    const datesArr = [...new Set(GraphData.map(d => d.date_time))];
    let dataByDate = datesArr.map((d) => ({
      key: (() => {
        if (dateRange <= 45) {
          return moment(d).format('MM/DD/YY HH:mm:ss');
        }
        return moment(d).hours(0).minutes(0).seconds(0).format('MM/DD/YY HH:mm:ss');
      })(),
      values: GraphData.filter(dd => dd.date_time === d)
    }));


    if (document.querySelector('.pumping-vs-drawdown-graph-svg') !== null) {
      document.querySelector('.pumping-vs-drawdown-graph-svg').remove();
    };
    
    const color = d3.scaleOrdinal()
      .domain(y1Data.series)
      .range(d3.schemeCategory10);
    
    setLegendData(y1Data.seriesNames.map((d,i) => ({
      location_desc: d,
      color: (() => {
        if (color.domain().length === 1) {
          return `rgb(66, 136, 181)`
        }
        return color(y1Data.series[i])
      })()
    })));

    // Create the SVG
    const svg = d3.select('.pumping-vs-drawdown-graph')
      .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('class', 'pumping-vs-drawdown-graph-svg')
      .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);
    
    // Create the x axis gridlines
    const xAxisGridlines = g => g
      .attr('class', 'grid')
      .style('fill', '#f5f5f6')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x).tickSize(-height).tickFormat("")
      );
    
    // Create the y-axis gridlines
    const yAxisGridlines = g => g
      .attr("class", "grid")
      .call(
        d3.axisLeft(y).tickSize(-width).tickFormat("").ticks(8)
      );
    
    // Create the x-axis
    const xAxis = g => g
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3.axisBottom(x).tickFormat(d3.timeFormat("%m/%d")).ticks(8)
      );

    // Create the y-axis
    const yAxisLeft = g => g
      .attr("class", "y-axis")
      .call(
        d3.axisLeft(y).ticks(8, "s")
      );
    
      // Create the y-axis
    const yAxisRight = g => g
      .attr("class", "y-axis")
      .attr("transform", `translate(${width},0)`)
      .call(
        d3.axisRight(y2).ticks(8, "s")
      );

    // Append the axis gridlines and axes themselves
    svg.append("g").call(xAxisGridlines);
    svg.append("g").call(yAxisGridlines);
    svg.append("g").call(xAxis);
    svg.append("g").call(yAxisLeft);
    svg.append("g").call(yAxisRight);

    // left y axis label
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x",0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Pumping (GPM)");   
    
    // right y axis label
    svg.append("text")
      .attr("transform", "rotate(90)")
      .attr("y", -width - margin.right)
      .attr("x",height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Water Level (feet above pump)");   

    y1Data.series.map((s,i) => {
      const filteredData = y1Data.data.filter(d => d.location_ndx === s);
      
      const pumpingLine = d3.line()
        .x(d => x(new Date(`${d.date_time}`)))
        .y(d => y(d.pumping_gpm));  
      
      const levelLine = d3.line()
        .x(d => x(new Date(`${d.date_time}`)))
        .y(d => y2(d.depth_above_pump_ft));  
      
      const levelArea = d3.area()
        .x(d => x(new Date(`${d.date_time}`)))
        .y0(d =>  y(0))
        .y1(d => y2(d.depth_above_pump_ft));

      svg.append("path")
        .datum(filteredData)
        .attr("class", "area")          
        .style("fill", `rgb(66, 136, 181)`)
        .attr("d", levelArea);
      
      svg.append("path")
        .datum(filteredData)
        .attr("class", "line")          
        .style("stroke", `rgb(66, 136, 181)`)        
        .style("opacity", 0.3)
        .attr("d", levelLine);

      svg.append("path")
        .datum(filteredData)
        .attr("class", "line")          
        .style("stroke", `rgb(230, 85, 13)`)
        .attr("d", pumpingLine);

    });
    
    const tooltipBar = svg.append('line').attr('class', 'tooltip-bar-pumping-vs-drawdown');
    const tooltipLine = svg.append('line').attr('class', 'tooltip-line-pumping-vs-drawdown');

    const rect = svg.append('rect')
        .attr('transform', 'translate(0,0)')
        .attr('class', 'overlay')
        .style('opacity', 0)
        .attr('width', width)
        .attr('height', height)
        .on('mousemove', function (d) {
          let roundedMouseDate;
          const mx = d3.mouse(this)[0]; 
          const mouseDate = moment(x.invert(mx));

          if (dateRange <= 45) {
            const remainderVal = dateRange <= 7 ? 15 : 60;
            const remainder = remainderVal - (mouseDate.minute() % remainderVal);
            roundedMouseDate = moment(mouseDate).add(remainder, "minutes").seconds(0);
          } else {
            roundedMouseDate = moment(mouseDate).hours(0).minutes(0).seconds(0);
          }

          const activeData = dataByDate
            .filter(d => d.key === roundedMouseDate.format('MM/DD/YY HH:mm:ss'));
          
          if (activeData.length > 0) {
            const formattedDate = dateRange <=  45 ? roundedMouseDate.format('MM/DD/YY @ HH:mm:ss') : roundedMouseDate.format('MM/DD/YY');
            createTooltip(tooltip, d3.event, color, activeData[0].values, formattedDate)
          }
          createTooltipLine(mx, height, tooltipBar, tooltipLine);
        })
        .on('click', function (d) {
          let roundedMouseDate;
          const mx = d3.mouse(this)[0]; 
          const mouseDate = moment(x.invert(mx));

          if (dateRange <= 45) {
            const remainderVal = dateRange <= 7 ? 15 : 60;
            const remainder = remainderVal - (mouseDate.minute() % remainderVal);
            roundedMouseDate = moment(mouseDate).add(remainder, "minutes").seconds(0);
          } else {
            roundedMouseDate = moment(mouseDate).hours(0).minutes(0).seconds(0);
          }

          const activeData = dataByDate
            .filter(d => d.key === roundedMouseDate.format('MM/DD/YY HH:mm:ss'));
          
          if (activeData.length > 0) {
            const formattedDate = dateRange <=  45 ? roundedMouseDate.format('MM/DD/YY @ HH:mm:ss') : roundedMouseDate.format('MM/DD/YY');
            createTooltip(tooltip, d3.event, color, activeData[0].values, formattedDate)
          }
          createTooltipLine(mx, height, tooltipBar, tooltipLine);
        })
        .on('mouseout', function() {
          tooltip.style('opacity', 0);
        });
  }

  /**
   * reset the loaded boolean whenever the locGroup changes
   */
  useEffect(() => {    
    setLoaded(false);
  }, [refreshData]);

  useEffect(() => {
    if (!loaded && GraphData.length > 0) {
      setLoaded(true);
      createChart();
    }
  }, [GraphData]);

  return (    
    <Fade in={true} style={{ transitionDelay: '500ms'}}>
      <React.Fragment>
        <Typography variant="h6" className={classes.sectionTitle}>Pumping vs Aquifer Drawdown</Typography>
        {loaded && GraphData.length > 0 ? (
          <Paper className={classes.paper}>
            <div className="pumping-vs-drawdown-graph"></div>
            <div className={classes.legend}>
              <div className={classes.legendItem}>
                <div className={classes.legendBox}></div>
                <div className={classes.legendText}>Level</div>
              </div>
              <div className={classes.legendItem}>
                <div className={classes.legendLine}></div>
                <div className={classes.legendText}>Pumping</div>
              </div>
            </div>  
          </Paper>
        ) : (            
          <Paper className={classes.paper}>
            <div className="pumping-vs-drawdown-graph" style={{visibility: `hidden`, height: 0}}></div>  
            <CircularProgress className={classes.progress} color="secondary" />
          </Paper>
        )}
      </React.Fragment>
    </Fade>
  );
}

PumpingVsDrawdownChart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  refreshData: PropTypes.bool,
  // locGroup: PropTypes.number.isRequired,
  // timePeriod: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(PumpingVsDrawdownChart);