import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Typography, Button, Popover } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import useFetchData from '../hooks/useFetchData';
import ListTable from './ListTable';

// create page styles
const styles = theme => ({
  root: {
    // padding: 15,
  },
  btn: {
    margin: `15px 15px 0 15px`
  },
  popover: {
    padding: 15,
  },
  textField: {
    display: 'block',
    marginTop: 15,
    marginBottom: 15,
  },
  progress: {
    margin: theme.spacing(3),
  }
});

const WellsOverview = ({ classes, headers }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeDate, setActiveDate] = useState(moment().format('YYYY-MM-DD'));
  const [data, isLoaded] = useFetchData(`system/wells-overview/${activeDate}`, headers, [activeDate]);

  const columns = [
    { id: 'location_desc', numeric: false, disablePadding: true, label: 'Well Name', chip: false, filterEnabled: true },
    { id: 'flow_gpm', numeric: false, disablePadding: false, label: 'Flow (GPM)', chip: false },
    { id: 'level_ft', numeric: false, disablePadding: false, label: 'Depth to Water (Feet)', chip: false },
    { id: 'pressure_psi', numeric: false, disablePadding: false, label: 'Pressure (PSI)', chip: false },
  ];

  const id = open ? 'simple-popover' : undefined;

  const handleCustomClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const handleCustomClose = () => {
    setOpen(false);
    setAnchorEl(null);
  }

  const handleDateChange = (event) => {
    const { value } = event.target
    setActiveDate(moment(value).format('YYYY-MM-DD'));
    handleCustomClose();
  }

  const handleTableTitle = () => {
    if (activeDate === moment().format('YYYY-MM-DD')) {
      if (data.length > 0) {
        const timestamp = data[0].timestamp;
        return `Snapshot for  ${moment(timestamp).format('M/D/YY @ HH:mm:ss')}`;
      }
    }
    return `Average Daily Values for ${moment(activeDate).format('M/D/YY')}`
  }

  return (    
    <Paper className={classes.root}>
      <Button variant="outlined" color="secondary" className={classes.btn} onClick={handleCustomClick}>Change Date</Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCustomClose}
        classes={{paper: classes.popover}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className={classes.startDate}>
          <TextField
            id="date"
            label="Snapshot Date"
            type="date"
            onChange={handleDateChange}
            value={activeDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      </Popover>
      {!isLoaded ? (
        <ListTable data={data} columns={columns} title={handleTableTitle()} selectionsEnabled={false} />
      ) : (
        <div>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      )}
    </Paper>
  )
}

WellsOverview.propTypes = {
  
};

export default withStyles(styles, { withTheme: true })(WellsOverview);
